<section class="content fixed-height">

  <form ngNativeValidate (submit)="activity.publish = !activity.publish;createOrUpdateActivity()">

    <div class="card info-group">
      <div class="card-header-black">
        <h2 class="card-title">Basic Information</h2>
      </div>
      <div class="card-block">
        <div class="row">

          <div class="col-6">
            <div class="form-group form-group--select">
              <label>Activity Type *</label>
              <div class="select">
                <select name="type" [disabled]="activity.type || activity.type != ''" [(ngModel)]="activity.type"
                        class="form-control" required>
                  <option *ngFor="let type of activity_types" [selected]="type == activity.type" [value]="type">
                    {{(type)?type:'Select Activity Type'}}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group form-group--float">
              <input name="theme" type="text" class="form-control" formControlFloat [(ngModel)]="activity.theme"
                     maxlength="900">
              <label>{{(activity.type)? capitalizeWord(activity.type) : 'Activity'}} Theme (upto 100 words) </label>
              <i class="form-group__bar"></i>
            </div>
          </div>

          <div class="col-6">
            <div class="form-group form-group--float">
              <input name="title" type="text" class="form-control" formControlFloat [(ngModel)]="activity.title"
              >
              <label class="text-capitalize">{{(activity.type)? capitalizeWord(activity.type) : 'Activity'}}
                Title </label>
              <i class="form-group__bar"></i>
            </div>
          </div>

          <div [ngClass]="(!activity.id||activity.id == 0) ? 'col-12':'col-6'">
            <image-upload [url]="imageUploadUrl"
                          [headers]="authHeader"
                          [max]="cover_img_limit"
                          [class]="'ng2ImgUpload pic-with-x'"
                          [formData]="activity_cover_pic_form_data"
                          [buttonCaption]="'Upload'"
                          [showMaxUploadLimitMessage]="cover_img_limit == 0"
                          [uploadedFiles]="coverImage"
                          (uploadFinished)="onUploadFinished($event)"
                          (removed)="onRemoved($event)"
                          [dropBoxMessage]="'Upload Cover Pic !'">
            </image-upload>
          </div>

          <div class="col-sm-12">
            <div class="form-group form-group--textarea">
              <label>About {{(activity.type) ? capitalizeWord(activity.type) : 'Activity'}} (Max 1000 words)</label>
              <textarea name="about" class="form-control" [(ngModel)]="activity.about"
                        placeholder="Enter summary of {{(activity.type) ? capitalizeWord(activity.type) : 'Activity'}} in few lines..."
                        [maxlength]="1000" rows="2" maxlength="4500"></textarea>
              <i class="form-group__bar"></i>
            </div>
          </div>

          <div class="col-sm-12 map-container form-group">
            <h5>Locate your school on map <i class="zmdi zmdi-pin address-pin"></i></h5>
            <input name="activity_address_pin" class="form-control" #activity_address_pin
                   [class.address_pin_admin]="role == 'admin'"
                   id="activity_address_pin" placeholder="Search activity area to Pin it on Map"
                   type="text">
            <div id="ActivityMap" #ActivityMap></div>
          </div>

          <div class="col-sm-6">
            <div class="form-group form-group--float">
              <input name="lat" type="text" class="form-control" #activity_lat
                     formControlFloat [(ngModel)]="activity.lat" (change)="reverseGeocodePosition(getGeoCord())"
                     required="required">
              <label class="text-capitalize">{{(activity.type) ? capitalizeWord(activity.type) : 'Activity'}}
                Latitude *</label>
              <i class="form-group__bar"></i>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group form-group--float">
              <input name="lon" type="text" class="form-control" #activity_lon
                     formControlFloat [(ngModel)]="activity.lon" (change)="reverseGeocodePosition(getGeoCord())"
                     required="required">
              <label class="text-capitalize">{{(activity.type) ? capitalizeWord(activity.type) : 'Activity'}}
                Longitude *</label>
              <i class="form-group__bar"></i>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group form-group--float">
              <input name="address" type="text" class="form-control" #activity_address
                     formControlFloat [(ngModel)]="activity.address" required="required">
              <label class="text-capitalize">{{(activity.type)? capitalizeWord(activity.type) : 'Activity'}}
                Address</label>
              <i class="form-group__bar"></i>
            </div>
          </div>

          <div class="col-md-6">
            <div class="form-group form-group--select">
              <label>{{(activity.type)? capitalizeWord(activity.type) : 'Activity'}} City *</label>

              <select name="city" [(ngModel)]="activity.city" class="form-control" required>
                <option [ngValue]="''" disabled>-- Select Option --</option>
                <option *ngFor="let city of city.data" [selected]="city.id == activity.city" [value]="city.id">
                  {{city.text}}
                </option>
              </select>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group form-group--select">
              <label class="text-capitalize">{{(activity.type)? capitalizeWord(activity.type) : 'Activity'}}
                State *</label>

              <select name="state" [(ngModel)]="activity.state" class="form-control" required>
                <option [ngValue]="''" disabled>-- Select Option --</option>
                <option *ngFor="let state of state.data" [selected]="state.id == activity.state" [value]="state.id">
                  {{state.text}}
                </option>
              </select>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group form-group--float">
              <input name="pincode" type="text" class="form-control" #activity_pincode
                     formControlFloat [(ngModel)]="activity.pincode" required="required">
              <label class="text-capitalize">{{(activity.type)? capitalizeWord(activity.type) : 'Activity'}}
                Pincode *</label>
              <i class="form-group__bar"></i>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group form-group--float">
              <input name="locality" type="text" class="form-control" #activity_locality
                     formControlFloat [(ngModel)]="activity.locality" required="required">
              <label class="text-capitalize">{{(activity.type)? capitalizeWord(activity.type) : 'Activity'}}
                Locality *</label>
              <i class="form-group__bar"></i>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group form-group--float">

              <label class="tag-input-label">{{(activity.type)? capitalizeWord(activity.type) : 'Activity'}}
                Tags</label>

              <tag-input [(ngModel)]="activity.tags" [theme]="'bootstrap'" [onlyFromAutocomplete]="true"
                         class="form-control tag-input" formControlFloat inputClass="tag-input-cus-cls" name="tags">

                <tag-input-dropdown [showDropdownIfEmpty]="true" [dynamicUpdate]="false"
                                    [focusFirstElement]="true" [displayBy]="'value'"
                                    [identifyBy]="'id'" [autocompleteItems]="activity_tags_for_bind">
                  <ng-template let-item="item">{{ item.value }}</ng-template>
                </tag-input-dropdown>
              </tag-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card info-group">
      <div class="card-header-black">
        <h2 class="card-title">Timing And Criteria Info</h2>
      </div>
      <div class="card-block">
        <div class="row">

          <div class="col-12 col-sm-12 col-md-6">
            <div class="form-group">
              <label>Total Seats Available </label>
              <input type="number" class="form-control" name="tot_seats" min="0" [(ngModel)]="activity.tot_seats">
              <i class="form-group__bar"></i>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-6">
            <div class="form-group">
              <label>Batch start Date* </label>
              <input type="date" class="form-control" name="start_date"
                     [(ngModel)]="activity.start_date" required>
              <i class="form-group__bar"></i>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label>Batch end Date* </label>
              <input type="date" class="form-control" name="end_date"
                     [(ngModel)]="activity.end_date" required>
              <i class="form-group__bar"></i>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label>Batch start Time* </label>
              <input type="time" class="form-control" name="start_time" [(ngModel)]="activity.start_time" required>
              <i class="form-group__bar"></i>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="form-group">
              <label>Batch end Time* </label>
              <input type="time" class="form-control" name="end_time" [(ngModel)]="activity.end_time" required>
              <i class="form-group__bar"></i>
            </div>
          </div>
        </div>

        <div class="row">

          <label class="col-12">Age Group* </label>
          <div class="col-sm-6">
            <div class="form-group form-group--select">
              <label>Min Age* </label>
              <input type="number" class="form-control" step="0.1" name="age_min" [minLength]="1" [maxLength]="4"
                     [min]="0" [max]="25"
                     [(ngModel)]="activity.age_min" required/>
              <i class="form-group__bar"></i>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group form-group--select">
              <label>Max Age* </label>
              <input type="number" class="form-control" step="0.1" name="age_max" [minLength]="1" [maxLength]="4"
                     [min]="0" [max]="25"
                     [(ngModel)]="activity.age_max" required/>
              <i class="form-group__bar"></i>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="card info-group">
      <div class="card-header-black">
        <h2 class="card-title">Transport Info</h2>
      </div>
      <div class="card-block">
        <div class="row">

          <div class="col-12 col-sm-12 col-md-6">
            <div class="status-title">
              <label for="pickNdrop-toggle">Pickup & Drop Available</label>
            </div>
            <div class="demo-inline-wrapper">
              <div class="form-group">
                <label for="pickNdrop-toggle">NO</label>
                <div class="toggle-switch toggle-switch--green">
                  <input name="pickNdrop" type="checkbox"
                         class="toggle-switch__checkbox"
                         id="pickNdrop-toggle"
                         [ngModel]="activity.pickNdrop == 1"
                         (ngModelChange)="activity.pickNdrop = $event?1:0">
                  <i class="toggle-switch__helper"></i>
                </div>
                <label for="pickNdrop-toggle">YES</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card info-group">
      <div class="card-header-black">
        <h2 class="card-title">Activities Details</h2>
      </div>
      <div class="card-block">
        <div class="row">
          <div class="col-12 text-justify margin-bt-20">
            <code class="text-danger">
              Please fill the main activities being covered during the camp
              under respective heading enabling parents to search and view your school’s summer camp.
              <br/><br/> Filling details under at least one category is mandatory.<br/><br/></code>
          </div>

          <div class="col-12 col-sm-12 col-md-6">
            <div class="form-group form-group--float">

              <label class="tag-input-label">Arts & Crafts (Example: Sketching, Origami, Calligraphy, Oil Painting) </label>

              <tag-input inputClass="tag-input-cus-cls" separatorKeyCodes="9" separatorKeys=","
                         modelAsStrings="true" addOnBlur="true" name="artsNCrafts" class="form-control tag-input"
                         [theme]="'bootstrap'" placeholder="" secondaryPlaceholder=""
                         [(ngModel)]="activity.artsNCrafts"
                         formControlFloat></tag-input>

              <!--<i class="form-group__bar"></i>-->
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-6">
            <div class="form-group form-group--float">

              <label class="tag-input-label">Dance & Drama (Example: Theater, Salsa, Kathak) </label>

              <tag-input inputClass="tag-input-cus-cls" separatorKeyCodes="9" separatorKeys=","
                         modelAsStrings="true" addOnBlur="true" name="danceNDrama" class="form-control tag-input"
                         [theme]="'bootstrap'" placeholder="" secondaryPlaceholder=""
                         [(ngModel)]="activity.danceNDrama"
                         formControlFloat></tag-input>

              <!--<i class="form-group__bar"></i>,-->
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-6">
            <div class="form-group form-group--float">

              <label class="tag-input-label">Music (Example: Guitar, Tabla, Vocal-Indian classical) </label>

              <tag-input inputClass="tag-input-cus-cls" separatorKeyCodes="9" separatorKeys=","
                         modelAsStrings="true" addOnBlur="true" name="music" class="form-control tag-input"
                         [theme]="'bootstrap'" placeholder="" secondaryPlaceholder=""
                         [(ngModel)]="activity.music"
                         formControlFloat></tag-input>

              <!--<i class="form-group__bar"></i>-->
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-6">
            <div class="form-group form-group--float">

              <label class="tag-input-label">Language (Example: English poetry, Spanish, Spoken English)</label>

              <tag-input inputClass="tag-input-cus-cls" separatorKeyCodes="9" separatorKeys=","
                         modelAsStrings="true" addOnBlur="true" name="language" class="form-control tag-input"
                         [theme]="'bootstrap'" placeholder="" secondaryPlaceholder=""
                         [(ngModel)]="activity.language"
                         formControlFloat></tag-input>

              <!--<i class="form-group__bar"></i>-->
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-6">
            <div class="form-group form-group--float">

              <label class="tag-input-label">Maths & Science (Example: Robotics, Abacus, STEM) </label>

              <tag-input inputClass="tag-input-cus-cls" separatorKeyCodes="9" separatorKeys=","
                         modelAsStrings="true" addOnBlur="true" name="mathsNSci" class="form-control tag-input"
                         [theme]="'bootstrap'" placeholder="" secondaryPlaceholder=""
                         [(ngModel)]="activity.mathsNSci"
                         formControlFloat></tag-input>

              <!--<i class="form-group__bar"></i>-->
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-6">
            <div class="form-group form-group--float">

              <label class="tag-input-label">Sports & Outdoors (Example: Swimming, Football, Yoga) </label>

              <tag-input inputClass="tag-input-cus-cls" separatorKeyCodes="9" separatorKeys=","
                         modelAsStrings="true" addOnBlur="true" name="gamesNSports" class="form-control tag-input"
                         [theme]="'bootstrap'" placeholder="" secondaryPlaceholder=""
                         [(ngModel)]="activity.gamesNSports"
                         formControlFloat></tag-input>

              <!--<i class="form-group__bar"></i>-->
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-6">
            <div class="form-group form-group--float">

              <label class="tag-input-label">Others </label>

              <tag-input inputClass="tag-input-cus-cls" separatorKeyCodes="9" separatorKeys=","
                         modelAsStrings="true" addOnBlur="true" name="others" class="form-control tag-input"
                         [theme]="'bootstrap'" placeholder="" secondaryPlaceholder=""
                         [(ngModel)]="activity.others"
                         formControlFloat></tag-input>

              <!--<i class="form-group__bar"></i>-->
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="card info-group">
      <div class="card-header-black">
        <h2 class="card-title">Fee Details</h2>
      </div>
      <div class="card-block">
        <div class="row">

          <div class="col-12 col-sm-12 col-md-6">
            <div class="form-group form-group--float">
              <input type="number" class="form-control" name="totalFee" [(ngModel)]="activity.totalFee"
                     formControlFloat required>
              <label>Total Fees* </label>
              <i class="form-group__bar"></i>
            </div>
          </div>

          <div class="col-12 col-sm-12 col-md-6">
            <div class="form-group form-group--float">
              <input type="text" class="form-control" name="feeDetail" [(ngModel)]="activity.feeDetail"
                     formControlFloat maxlength="2250"/>
              <label>Fees Details (upto 250 words)</label>
              <i class="form-group__bar"></i>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="card info-group">
      <div class="card-header-black">
        <h2 class="card-title">Home work help</h2>
      </div>
      <div class="card-block">
        <div class="row">

          <div class="col-12 col-sm-12 col-md-6">
            <div class="status-title">
              <label for="hmWrkHlp-toggle">Home Work Help</label>
            </div>
            <div class="demo-inline-wrapper">
              <div class="form-group">
                <label for="hmWrkHlp-toggle">NO</label>
                <div class="toggle-switch toggle-switch--green">
                  <input name="hmWrkHlp" type="checkbox"
                         class="toggle-switch__checkbox"
                         id="hmWrkHlp-toggle"
                         [ngModel]="activity.hmWrkHlp == 1"
                         (ngModelChange)="activity.hmWrkHlp = $event?1:0">
                  <i class="toggle-switch__helper"></i>
                </div>
                <label for="hmWrkHlp-toggle">YES</label>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="card info-group">
      <div class="card-header-black">
        <h2 class="card-title">Gallery</h2>
      </div>
      <div class="card-block">
        <div class="row">
          <div class="col-12">
            <p class="text-info">You have uploaded {{gallery_img_length}} out of {{gallery_img_limit}}</p>
            <br/>
          </div>
          <div class="col-12">
            <image-upload #activity_gallery
                          [url]="imageUploadUrl"
                          [headers]="authHeader"
                          [max]="gallery_img_limit"
                          [class]="'ng2ImgUpload pic-with-x'"
                          [formData]="activity_gallery_form_data"
                          [buttonCaption]="'Upload'"
                          [showMaxUploadLimitMessage]="false"
                          [uploadedFiles]="galleryImages"
                          (uploadFinished)="onUploadFinished($event)"
                          (removed)="onRemoved($event)"
                          [dropBoxMessage]="'Upload Gallery Photos!'">
            </image-upload>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <button type="button" (click)="saveActivity()" class="btn btn-primary">
          Save
        </button>

        <button type="button" *ngIf="activity.status" (click)="deleteActivity()" class="btn btn-danger">Delete
        </button>

        <button type="submit" class="btn btn-success">{{(activity.publish)?'Un Publish':'Publish'}}</button>

        <button type="button" *ngIf="activity.publish" (click)="openPreview(activity)" class="btn btn-warn">
          Preview
        </button>
      </div>
    </div>

  </form>

</section>
