// @ts-nocheck
import {EventEmitter, Inject, Injectable} from '@angular/core';
import {Headers, Http, RequestOptions, URLSearchParams} from '@angular/http';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
declare var jwt_decode: any;
import * as Cookies from 'js-cookie';
import {environment} from '../../../environments/environment';
import * as _ from 'lodash';
import {UserModel} from './user.model';

const API_BASE_URL = '/api/'; // relative url is ok for api on same domain

const SCHOOL_TYPES = [
    {id: 1, text: 'Day School'},
    {id: 2, text: 'Pre School'},
    {id: 3, text: 'Residential School'},
    {id: 4, text: 'Day cum Residential'},
    {id: 5, text: 'PU College'},
    {id: 6, text: 'Online School'}
];

const SUBSCRIPTION_PRICING = [
    {type: 1, displayPrice: 75000, price: 88500, old_price: 59000, plainText: 'Day School'},
    {type: 2, displayPrice: 35000, price: 41300, old_price: 20000, plainText: 'Pre School'},
    {type: 3, displayPrice: 100000, price: 118000, old_price: 100000, plainText: 'Boarding School'},
    {type: 4, displayPrice: 100000, price: 118000, old_price: 100000, plainText: 'Day cum Boarding'},
    {type: 5, displayPrice: 50000, price: 59000, old_price: 50000, plainText: 'PU College'},
    {type: 6, displayPrice: 50000, price: 59000, old_price: 50000, plainText: 'Online School'}
];

const price_lead_package_preschool = [
      {count: 5, cost_pl: 50, total: 5 * 59, validity: 3},
      {count: 10, cost_pl: 50, total: 10 * 59, validity: 6},
      {count: 20, cost_pl: 50, total: 20 * 59, validity: 12},
];

const ACTIVITY_TYPES = ['', 'SUMMERCAMP', 'MUSIC', 'DANCE', 'YOGA', 'GYMNASTICS', 'MARTIAL ARTS', 'FARMING', 'COOKING', 'THEATER'];

const USER_ROLES = [
  {'text': 'Activity', 'value': 'activity'},
  {'text': 'Admin', 'value': 'admin'},
  {'text': 'School Chain Admin', 'value': 'chainadmin'},
  {'text': 'Counsellor', 'value': 'counsellor'},
  {'text': 'Expired Admin', 'value': 'expiredadmin'},
  {'text': 'Finance Manager', 'value': 'finance'},
  {'text': 'Lead Manager', 'value': 'lms'},
  {'text': 'Parent', 'value': 'parent'},
  {'text': 'School', 'value': 'school'},
  {'text': 'School Relation Manager', 'value': 'srm'}
];

const BLOCKED_EMAIL = ['rohit.retwal222@gmail.com'];
const BLOCKED_PHONE = ['8126716157', '8700057124', '9324198941', '8007741483','9122347664','7289930297','9650635487','09650635487','9711755151'];

@Injectable()
export class ApiService {
  public static production = environment.production;
  public static domainName = environment.domainName;

  // If this school is paid
  private subscribed = false;
  public exiting_credit: EventEmitter<any> = new EventEmitter();

  public WEBSITE_URL = environment.website_url;
  public API_KEY = environment.api_key;
  public IS_PAYMENT_LIVE = environment.is_payment_live;
  public KEY_ID_TEST = environment.key_id_test;
  public KEY_ID_LIVE = environment.key_id_live;
  public KEY_SECRET_TEST = environment.key_secret_test;
  public KEY_SECRET_LIVE = environment.key_secret_live;
  public activity_amount = environment.activity_amount;
  public access_token: string;
  private requestOptions: RequestOptions;
  public user: UserModel;
  public role: string;
  private ppl: { type_id: number; price: number }[];
  private ppel: { type_id: number; price: number }[];
  public rp_value = 1;

  public APPLICATION_TAGS: any = [
    {id: 1, value: 'Indoor Sports'},
    {id: 2, value: 'Outdoor Sports'},
    {id: 3, value: 'Cricket'},
    {id: 4, value: 'Football'},
    {id: 5, value: 'Art & Craft'},
    {id: 6, value: 'Guitar'},
    {id: 7, value: 'Keyboard'},
    {id: 8, value: 'Maths'},
    {id: 9, value: 'Science'},
    {id: 10, value: 'Coding'},
    {id: 11, value: 'Robotics'},
    {id: 12, value: 'Swimming'},
    {id: 13, value: 'STEM'},
    {id: 14, value: 'Dance'},
    {id: 15, value: 'Story Telling'},
    {id: 16, value: 'Language'},
    {id: 17, value: 'Water Pool'},
    {id: 18, value: 'Summer Camp'},
    {id: 19, value: 'Gymnastics'},
    {id: 20, value: 'Yoga'},
    {id: 21, value: 'Cooking'}
  ];


  // public school: SchoolInfoModel;

  public static getBaseUrl() {
    return API_BASE_URL;
  }

  public static getUserRoles() {
    return USER_ROLES;
  }

  public static getBlockedEmails() {
    return BLOCKED_EMAIL;
  }
  public static getBlockedPhones() {
    return BLOCKED_PHONE;
  }

  public static getActivityTypes() {
    return ACTIVITY_TYPES;
  }

  public static getSchoolTypes() {
    return SCHOOL_TYPES;
  }

  public static getSubscriptionPricing() {
    return SUBSCRIPTION_PRICING;
  }

  public static getPreSchoolLeadPackages() {
    return price_lead_package_preschool;
  }


  public isSubscribed(callback) {
    if (this.subscribed)
      callback(null, this.subscribed);
    else
      this.refreshSubscribed(callback);
  }

  public setSubscribed(status) {
    this.subscribed = status;
  }

  public getPaymentKeyId() {
    if (this.IS_PAYMENT_LIVE) {
      return this.KEY_ID_LIVE;
    } else {
      return this.KEY_ID_TEST;
    }
  }

  public getPaymentSecretId() {
    if (this.IS_PAYMENT_LIVE) {
      return this.KEY_SECRET_LIVE;
    } else {
      return this.KEY_SECRET_TEST;
    }
  }

  constructor(private http: Http, private toastrService: ToastrService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              @Inject(DOCUMENT) private document: any) {

    this.ppl = [
      {type_id: 2, price: 50},
      {type_id: 1, price: 800},
      {type_id: 3, price: 1200},
      {type_id: 4, price: 1200},
      {type_id: 5, price: 600},
      {type_id: 6, price: 600}
    ];

    this.ppel = [
      {type_id: 2, price: 0},
      {type_id: 1, price: 0},
      {type_id: 3, price: 0},
      {type_id: 4, price: 0},
      {type_id: 5, price: 0},
      {type_id: 6, price: 0}
    ];

    if (window.location.href.includes('school')) this.role = 'school';
    else if (window.location.href.includes('parent')) this.role = 'parent';
    this.init();
  }

  init() {
    // if (localStorage.getItem('access_token') === 'undefined')  localStorage.setItem('access_token', '');
    // this.access_token = localStorage.getItem('access_token') || Cookies.get('access_token') || '';
    this.access_token = Cookies.get('access_token_' + this.role) || '';
    localStorage.setItem('access_token', this.access_token);

    if (!localStorage.getItem('user') && this.access_token) {
      const payload = jwt_decode(this.access_token);
      localStorage.setItem('user', JSON.stringify(payload.user));
    }
    this.user = <UserModel>JSON.parse(localStorage.getItem('user'));
    /*if (localStorage.getItem('school'))
      this.school = <SchoolInfoModel>JSON.parse(localStorage.getItem('school'));*/

    // add authorization header with jwt token
    let headers = new Headers({'Authorization': 'Bearer ' + this.access_token});
    this.requestOptions = new RequestOptions({headers: headers});
  }

  public updateCredit(credit: any): void {
    this.exiting_credit.emit(credit);
  }

  public refreshSubscribed(callback) {
    let _self = this;
    this.get('subscriptions/get', null, function (err, response) {
      if (response.data) {
        if (response.data.status) _self.setSubscribed(true);
        callback(err, _self.subscribed);
      }
    });
  }

  public getPpl(type_id) {
    let price = 0;
    this.ppl.forEach((value) => {
      if (value.type_id === parseInt(type_id)) price = value.price;
    });
    return price;
  }

  public getEpl(type_id) {
    let price = 0;
    this.ppel.forEach((value) => {
      if (value.type_id === parseInt(type_id)) price = value.price;
    });
    return price;
  }

  login(token: string) {
    console.log('api service', 'login', token);
    // this.logout(); // clear any previous session or storage

    localStorage.removeItem('current_activity');
    localStorage.removeItem('current_role');
    localStorage.removeItem('activity_owner');

    const payload = jwt_decode(token);
    // console.log('login', payload);
    // store user and jwt token in local storage to keep user logged in between page refreshes
    /*if (payload.user.school) localStorage.setItem('school', JSON.stringify(payload.user.school));
    delete payload.user.school;*/

    localStorage.setItem('user', JSON.stringify(payload.user));
    localStorage.setItem('access_token', token);
    Cookies.set('access_token_' + this.role, token, {domain: this.domainName, expires: 15});

    this.init();
    if (this.user.role === 'school' || this.user.role === 'admin' || this.user.role === 'chainadmin') {
      const registerPlan = localStorage.getItem('registerPlan');
      sessionStorage.setItem('current_role', 'school');
      if (registerPlan && registerPlan.includes('paid'))
        this.router.navigate(['/school/payment'], {queryParams: {plan: registerPlan}});
      else
        this.router.navigate(['/school']);

      localStorage.removeItem('registerPlan');
    } else if (this.user.role === 'parent') {
      sessionStorage.setItem('current_role', this.user.role);
      window.location.assign(Cookies.get('return_url') || this.WEBSITE_URL);
    } else if (this.user.role === 'activity') {
      sessionStorage.setItem('current_role', this.user.role);
      this.router.navigate(['/activity']);
    } else {
      this.toastrService.error('Invalid user type');
      this.logout();
    }

  }

  logout() {
    // clear token remove user from local storage to log user out
    let role = '';
    if (this.user) role = this.user.role;
    this.access_token = null;
    this.requestOptions = null;
    this.user = null;
    localStorage.removeItem('user');
    localStorage.removeItem('access_token');
    localStorage.removeItem('current_activity');
    localStorage.removeItem('current_role');
    localStorage.removeItem('activity_owner');
    this.subscribed = false;
    Cookies.remove('access_token_' + this.role, {domain: this.domainName});
    if (role === 'parent') this.document.location.href = this.WEBSITE_URL;
    else this.router.navigate(['./' + role + '/auth/login']);

  }

  getSchoolId() {
    const school_id = this.activatedRoute.snapshot.queryParams['school'] || sessionStorage.getItem('admin_school_id') || ''; //
    sessionStorage.setItem('admin_school_id', school_id);
    return school_id;
  }

  getActivityAdminId() {
    const activity_owner = this.activatedRoute.snapshot.queryParams['activity_owner'] || localStorage.getItem('activity_owner') || '';
    localStorage.setItem('activity_owner', activity_owner);
    return activity_owner;
  }

  getRoleOfCurrentContext() {
    let role = sessionStorage.getItem('current_role');
    if (!role) role = this.role;
    return role;
  }

  setParamsAccToContext(endpoint, params) {
    let filterArr = [
      'states', 'cities', 'schooltypes', 'grades', 'auth/signin'
    ];
    if (_.includes(filterArr, endpoint)) {
      return params;
    }
    let role = this.getRoleOfCurrentContext();
    if (role === 'activity') {
      params.set('activity_owner', this.getActivityAdminId());
    } else if (role === 'parent') {
      console.log('user_id = ', this.user.id);
    } else {
      params.set('school', this.getSchoolId()); // only for admin user
    }
    return params;
  }

  setPostDataAccToContext(endpoint, params) {
    let filterArr = [
      'states', 'cities', 'schooltypes', 'grades', 'auth/signin'
    ];
    if (_.includes(filterArr, endpoint)) {
      return params;
    }
    let role = this.getRoleOfCurrentContext();
    if (role === 'activity') {
      params['activity_owner'] = this.getActivityAdminId();
    } else if (role === 'parent') {
      console.log('user_id = ', this.user.id);
    } else {
      params['school'] = this.getSchoolId();
    }
    return params;
  }

  get(endpoint: string, data?: object, callback?: any) {
    // console.log('Api Service, Get', endpoint, data);

    // edit local copy of request if these params are not globally needed
    let requestOptions = Object.create(this.requestOptions);
    let params = new URLSearchParams();
    if (data) {
      _.forOwn(data, function (value, key) {
        params.set(key, value);
      });
    }
    params = this.setParamsAccToContext(endpoint, params);
    requestOptions.params = <URLSearchParams>params;

    // console.log(data, params, requestOptions);

    return this.http.get(API_BASE_URL + endpoint, requestOptions)
      .subscribe(
        response => {
          if (response.json().code === 'E_UNAUTHORIZED') {
            this.logout();
          } else {
            return callback(null, response.json());
          }
        },
        error => {
          this._handleError(error, callback);
        }
      );

  }

  post(endpoint, data, callback) {
    // console.log('debug, school_id=', this.getSchoolId());
    // if (data && this.role !== 'parent') data.school = this.getSchoolId();

    if (data) data = this.setPostDataAccToContext(endpoint, data);


    return this.http.post(API_BASE_URL + endpoint, data, this.requestOptions)
      .subscribe(
        response => {
          return callback(null, response.json());
        },
        error => {
          this._handleError(error, callback);
        }
      );
  }

  put(endpoint, data, callback) {
    // if (data && this.role !== 'parent') data.school = this.getSchoolId();

    if (data) data = this.setPostDataAccToContext(endpoint, data);

    return this.http.put(API_BASE_URL + endpoint, data, this.requestOptions)
      .subscribe(
        response => {
          return callback(null, response.json());
        },
        error => {
          this._handleError(error, callback);
        }
      );
  }

  delete(endpoint, data, callback) {
    // if (data && this.role !== 'parent') data.school = this.getSchoolId();

    if (data) data = this.setPostDataAccToContext(endpoint, data);

    const requestOptions = this.requestOptions;
    requestOptions.body = data;
    return this.http.delete(API_BASE_URL + endpoint, this.requestOptions)
      .subscribe(
        response => {
          return callback(null, response.json());
        },
        error => {
          this._handleError(error, callback);
        }
      );
  }

  _handleError(error, callback) {
    // console.log(error);
    if (error.status === 500) this.toastrService.error('Some error Occurred, Try again!');

    // todo make incorrect login response as non 401
    /*else if (error.status === 401 && error.json().code === 'E_UNAUTHORIZED') {
      // this.toastrService.error('Not authorized to perform this request!');
      this.logout();
      this.router.navigate(['/school/auth/login']);
    }*/
    return callback(error.json());
  }

  extGet(endpoint: string, data?: object, callback?: any) {
    let requestOptions = Object.create(this.requestOptions);
    let params = new URLSearchParams();
    if (data) {
      _.forOwn(data, function (value, key) {
        params.set(key, value);
      });
      requestOptions.params = <URLSearchParams>params;
    }
    return this.http.get(endpoint, requestOptions)
      .subscribe(
        response => {
          let responseParsed: string;
          try {
            responseParsed = response.json();
          } catch ( err) {
            responseParsed = response.toString();
          }
          return callback(null, responseParsed);
        },
        error => {
          this._handleError(error, callback);
        }
      );

  }

  extPost(endpoint, data, callback) {
    let requestOptions = Object.create(this.requestOptions);
    let params = new URLSearchParams();
    if (data) {
      _.forOwn(data, function (value, key) {
        params.set(key, value.toString());
      });
      requestOptions.params = <URLSearchParams>params;
    }
    return this.http.get(endpoint, requestOptions)
      .subscribe(
        response => {
          return callback(null, response.json());
        },
        error => {
          this._handleError(error, callback);
        }
      );
  }


}
